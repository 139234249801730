import {
  IFormUrls,
  FormSetting,
  IAppSetting,
  ISetting,
  INav,
  IFormGroup,
  IFormField,
  IOption,
  ISlide,
  IDayMonthYear,
  INavDirection,
  ICodeDescription,
  IKeyValue,
  IFormSchema,
  IResourceCollectionOptions,
  IDataCollectionOptions,
} from "../typings";
import axios from "axios";
import parseISO from "date-fns/esm/parseISO";
import set from "set-value";

export function getJsonValue(o: any, s: string) {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (o === Object(o) && k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

export function getFormSettings(root: HTMLElement | null): any | FormSetting[] {
  if (!root) return null;

  let formSettings: FormSetting[] = [];

  for (var i = 0; i < root.attributes.length; i++) {
    // Store reference to current attr
    const attr = root.attributes[i];
    // If attribute nodeName starts with 'data-'
    if (/^data-/.test(attr.nodeName)) {
      formSettings.push({
        key: attr.nodeName,
        value: attr.nodeValue,
      });
    }
  }

  return formSettings;
}

const currentScriptPath = function (level = 0) {
  let line = new Error().stack?.split("\n")[level + 1].split("@").pop();
  return line?.substr(0, line.lastIndexOf("/") + 1);
};

const currentScriptPathX = () => {
  let level = 0;
  let error = new Error();
  console.log(error);
  let line = error.stack?.split("\n")[level + 1].split("@").pop();
  return line?.substr(0, line.lastIndexOf("/") + 1);

  /*
  let script = document.currentScript;
  let path;
  if (script) path = script.src.substr(0, script.src.lastIndexOf("/") + 1);
  if(path){
    fetch(`${path}data-overrides.json`)
    .then((response) => response.text())
    .then((responseText) => {
      console.log(JSON.parse(responseText));
    });
  }
  */
};

export function getAppSettings(root: HTMLElement | null): ISetting[] {
  //new script()

  //console.log("currentScriptPath", currentScriptPath());

  if (!root) return [];

  let settings: ISetting[] = [];

  for (var i = 0; i < root.attributes.length; i++) {
    // Store reference to current attr
    const attr = root.attributes[i];
    // If attribute nodeName starts with 'data-'
    if (/^data-/.test(attr.nodeName)) {
      settings.push({
        key: attr.nodeName,
        value: attr.nodeValue,
      });
    }
  }

  return settings;
}

export function getAppSettingsWithOverrides(
  root: HTMLElement | null,
  overrides: object[]
): ISetting[] {
  if (!root) return [];

  let settings: ISetting[] = [];

  for (var i = 0; i < root.attributes.length; i++) {
    // Store reference to current attr
    const attr = root.attributes[i];
    // If attribute nodeName starts with 'data-'
    console.log("attr.nodeName", attr.nodeName);
    if (/^data-/.test(attr.nodeName)) {
      settings.push({
        key: attr.nodeName,
        value: attr.nodeValue,
      });
    }
  }

  return settings;
}

/**
 * Merges pre-set and supplied app settings
 * @param appSettings the pre-set settings for the form
 * @param settings the settings that is supplied from 'data-' attributes of the app root html element
 */
export function parseAppSettings(
  appSettings?: Array<IAppSetting>,
  settings?: ISetting[]
): IAppSetting[] {
  if (!appSettings) return [];
  if (!settings) return [];

  // create a new list to store the parse items
  let parsedAppSettings: Array<IAppSetting> = [];

  // loop thru all app settings
  appSettings.forEach((as) => {
    // search settings found on html attributes
    let setting = settings.find((s) => s.key === as.key);
    // get value from settings, else set as null
    let value = setting && setting.value ? setting.value : null;

    // update the parsed app settings
    parsedAppSettings.push({
      key: as.key,
      value: value === null ? "" : value,
      required: as.required || false,
      requiredValidationMessage:
        as.requiredValidationMessage ||
        `Please provide a value for key '${as.key}'`,
    });
  });

  // return parsed app settings
  return parsedAppSettings;
}

/**
 * Merges pre-set and supplied app settings
 * @param appSettings the pre-set settings for the form
 * @param settings the settings that is supplied from 'data-' attributes of the app root html element
 */
export function parseAppSettingsWithDefaults(
  appSettings?: Array<IAppSetting>,
  settings?: ISetting[]
): IAppSetting[] {
  if (!appSettings) return [];
  if (!settings) return [];

  // create a new list to store the parse items
  let parsedAppSettings: Array<IAppSetting> = [];

  // loop thru all app settings
  appSettings.forEach((as) => {
    // search settings found on html attributes
    let setting = settings.find((s) => s.key === as.key);
    // acquire default value
    let defaultValue = as.valueIfNotSet ? as.valueIfNotSet : null;
    // get value from settings, else set as null
    let value = setting && setting.value ? setting.value : defaultValue;

    // update the parsed app settings
    parsedAppSettings.push({
      key: as.key,
      value: value === null ? "" : value,
      required: as.required || false,
      requiredValidationMessage:
        as.requiredValidationMessage ||
        `Please provide a value for key '${as.key}'`,
    });
  });

  // return parsed app settings
  return parsedAppSettings;
}

export function getValidationErrors(
  appSettings?: IAppSetting[]
): IAppSetting[] | null {
  if (!appSettings) return null;
  const validationErrors = appSettings.filter((a) => {
    return a.required === true && (a.value === "" || a.value === null);
  });
  return validationErrors;
}

export function getFormUrls(root: HTMLElement | null): IFormUrls {
  if (root === null) return { apiUrl: "" };

  let formUrls: IFormUrls = {
    apiUrl: root.getAttribute("data-api-url") || "",
    baseUrl: root.getAttribute("data-base-url") || "",
    successRedirectUrl: root.getAttribute("data-success-redirect-url") || "",
    absoluteSuccessRedirectUrl: "",
  };

  formUrls = initializeFormUrls(formUrls);

  return formUrls;
}

function initializeFormUrls(formUrls: IFormUrls): IFormUrls {
  let apiUrlPrefix = "https://";
  let apiUrlsuffix = "webservices.1stcontact.com/crmproxy";

  // if api url is not set, we need to infer it from current domain
  if (!formUrls.apiUrl || formUrls.apiUrl === "") {
    // get hostname
    var hostname = window.location.hostname;

    // localhost check
    if (hostname === "localhost") {
      formUrls.apiUrl = `${apiUrlPrefix}local-${apiUrlsuffix}`;
    }
    // dev-, staging- and live check
    else if (hostname.startsWith("dev-")) {
      formUrls.apiUrl = `${apiUrlPrefix}dev-${apiUrlsuffix}`;
    } else if (hostname.startsWith("stg-")) {
      formUrls.apiUrl = `${apiUrlPrefix}stg-${apiUrlsuffix}`;
    } else {
      formUrls.apiUrl = `${apiUrlPrefix}${apiUrlsuffix}`;
    }
  }

  // if base url is not set, there's no need to redirect
  if (
    !formUrls.baseUrl ||
    formUrls.baseUrl === "" ||
    !formUrls.successRedirectUrl ||
    formUrls.successRedirectUrl === ""
  ) {
    // set redirect to null since we wont redirect
    formUrls.absoluteSuccessRedirectUrl = "";
  } else {
    // if base url is set as a single forward slash
    if (formUrls.baseUrl === "/") {
      // take the current origin url
      formUrls.baseUrl = window.location.origin;
    }
    // where the form will take you after submission
    formUrls.absoluteSuccessRedirectUrl =
      formUrls.baseUrl.replace(/\/$/, "") +
      "/" +
      formUrls.successRedirectUrl.replace(/^~*\/*/, "");
  }

  return formUrls;
}

export function cloner<T>(objectToClone: object): T {
  return objectToClone as unknown as T;
}

export function clone<T>(objectToClone: object): T {
  return JSON.parse(JSON.stringify(objectToClone));
}

export function getSetting(
  appSettings: Array<IAppSetting>,
  settingName: string
): string | undefined {
  let setting = appSettings.find((a) => a.key === settingName) as IAppSetting;

  if (!setting) {
    return undefined;
  }

  return setting.value;
}

/**
 * Get an app setting specifying the return type
 * @param settings - the IAppSettings array to look for settings
 * @param settingName - the name of the setting
 * @param returnValueIfUndefined - what to return if the value is not found, otherwise returns null
 */
export function coerceAppSetting<T>(
  settings: IAppSetting[] | undefined,
  settingName: string,
  returnValueIfUndefined: any = null
): T {
  if (!settings) return returnValueIfUndefined;
  let value = getSetting(settings, settingName);
  if (value !== undefined) {
    return value as unknown as T;
  }
  return returnValueIfUndefined;
}

export function getFirstErrorHtmlElement(instance: any) {
  // *
  // smooth scroll to the first error element on the page
  // ****************************************************
  // get the instance schema of the first error field
  let errorFieldComponent;
  for (let i = 0; i < instance.$refs["tg-group"].length; i++) {
    let group = instance.$refs["tg-group"][i];

    for (let j = 0; j < group.$children.length; j++) {
      let child = group.$children[j];

      if (child.field.model === instance.errors[0].field.model) {
        errorFieldComponent = child;
        break;
      }
    }

    if (errorFieldComponent) break;
  }

  // create handle to function helper
  let handle = instance.$refs["tg-group"][0].$children[0];

  // get DOM id of error field in question
  let fieldId = handle.getFieldID(instance.errors[0].field);

  // initialize the default scrollToElement
  let scrollToElement = document.getElementById(fieldId);

  // return element as alternatve, since dom id does not as exist
  if (!scrollToElement) {
    return errorFieldComponent.$el;
  }

  // use a 'starts with' query selector for radios and checklists
  if (["radios", "checklist"].indexOf(errorFieldComponent.type) !== -1) {
    scrollToElement = document.querySelector(`[id^='${fieldId}']`);
  }

  return scrollToElement;
}

export function getNavForSlide(nav: INav, slide: ISlide) {
  // create ref to state object
  let clonedNav = clone<INav>(nav);
  // set current slide name
  clonedNav.currentSlideComponent = slide.name;
  // return updated nav
  return clonedNav;
}

export interface CollectionSettings {
  model: string;
  url: string;
  formSchema: any;
  optionsObjectPath: string;
  valueKey?: string;
  textKey?: string;
  textKeysToOmit?: Array<string>;
  textKeysTopList?: Array<string>;
}

export function sortArrayByProperty(array: any[], sortProperty: string) {
  return array.slice(0).sort(function (a, b) {
    return a[sortProperty] > b[sortProperty]
      ? 1
      : a[sortProperty] < b[sortProperty]
      ? -1
      : 0;
  });
}

export function sortByKey<O>(
  key: keyof O,
  decending: boolean = false
): (a: O, b: O) => number {
  const order = decending ? -1 : 1;
  return (a, b): number => {
    const valA = a[key];
    const valB = b[key];
    if (valA < valB) {
      return -order;
    } else if (valA > valB) {
      return order;
    } else {
      return 0;
    }
  };
}

// steps
// get data
// process data
// - sort
// - promotion
// - add divider
// - ommisions

export function omitArrayTextKeys(array: IOption[], omissions: string[]) {
  omissions.forEach((o) => {
    let index = array.findIndex((item) => item.name === "N/A");
    if (index !== -1) {
      array.splice(index, 1);
    }
  });

  return array;
}

export function setFormSchemaModelArray(
  model: string,
  formSchema: any,
  array: IOption[]
) {
  // loop thru groups
  formSchema.groups.forEach(
    (
      group: IFormGroup,
      group_index: number,
      group_array: Array<IFormGroup>
    ) => {
      group.fields.forEach(
        (
          field: IFormField,
          field_index: number,
          field_array: Array<IFormField>
        ) => {
          if (field.model === model) {
            field.values = array;
          }
        }
      );
    }
  );
}

export function setFormSchemaModelAttribute(
  model: string,
  formSchema: any,
  attributeKey: string,
  attributeValue: string | number
) {
  // loop thru groups
  formSchema.groups.forEach(
    (
      group: IFormGroup,
      group_index: number,
      group_array: Array<IFormGroup>
    ) => {
      group.fields.forEach(
        (
          field: IFormField,
          field_index: number,
          field_array: Array<IFormField>
        ) => {
          if (field.model === model) {
            field.attributes[attributeKey] = attributeValue;
          }
        }
      );
    }
  );
}

export const setFormSchemaFieldValue = (
  model: string,
  formSchema: any,
  key: string,
  value: any
) => {
  // loop thru groups
  formSchema.groups.forEach(
    (
      group: IFormGroup,
      group_index: number,
      group_array: Array<IFormGroup>
    ) => {
      group.fields.forEach(
        (
          field: IFormField,
          field_index: number,
          field_array: Array<IFormField>
        ) => {
          if (field.model === model) {
            set(field, key, value);
          }
        }
      );
    }
  );
};

/**
 * Turns off validation for a form schema definition.
 * Perfect if you want to make all fields optional when testing
 * @param formSchema
 * @param attributeKey
 * @param attributeValue
 */
export const disableFormSchemaValidation = (formSchema: any) => {
  // loop thru groups
  formSchema.groups.forEach(
    (
      group: IFormGroup,
      group_index: number,
      group_array: Array<IFormGroup>
    ) => {
      group.fields.forEach(
        (
          field: IFormField,
          field_index: number,
          field_array: Array<IFormField>
        ) => {
          field.required = false;
          field.validator = () => {};
        }
      );
    }
  );
};

export function setCheckBoxInlineValue(
  model: string,
  formSchema: any,
  location: boolean,
  attributeValue: string | number
) {
  // loop thru groups
  formSchema.groups.forEach(
    (
      group: IFormGroup,
      group_index: number,
      group_array: Array<IFormGroup>
    ) => {
      group.fields.forEach(
        (
          field: IFormField,
          field_index: number,
          field_array: Array<IFormField>
        ) => {
          if (field.model === model) {
            if (location === true) {
              field.checkboxInlineBooleanTrueValue = attributeValue;
            } else {
              field.checkboxInlineBooleanFalseValue = attributeValue;
            }
          }
        }
      );
    }
  );
}

export function setFieldLabelValue(
  model: string,
  formSchema: any,
  attributeValue: string
) {
  // loop thru groups
  formSchema.groups.forEach(
    (
      group: IFormGroup,
      group_index: number,
      group_array: Array<IFormGroup>
    ) => {
      group.fields.forEach(
        (
          field: IFormField,
          field_index: number,
          field_array: Array<IFormField>
        ) => {
          if (field.model === model) {
            field.label = attributeValue;
          }
        }
      );
    }
  );
}

export function promoteArrayValues(
  array: any[],
  promotions: string[],
  addDividerAfter: boolean = false,
  dividerKey: string = "---",
  dividerValue: string = "---"
) {
  // run topList array in reverse so it add top items in the order specified
  for (let i = promotions.length - 1; i >= 0; i--) {
    // get handle to top item
    const topItem = promotions[i];
    // find index where top item is
    var itemIndex = array.findIndex((a) => a.name === topItem);
    // promote the current top-list item to the top of the array
    if (itemIndex !== -1) {
      array.splice(
        0, // new index,
        0, // no removal
        array.splice(itemIndex, 1)[0] // detach the item and return it
      );
    }
  }

  // should we add a divider after the top list?
  if (addDividerAfter === true) {
    array.splice(promotions.length, 0, {
      id: null,
      value: null,
      name: dividerValue,
    });
  }

  return array;
}

/**
 * Populates a 'select' form control from a json endpoint
 * @param model - the name of the model to populate inside the formSchema
 * @param url - the json resource url
 * @param formSchema - the vue-form-generator schema object where the model reside
 * @param optionsObjectPath - the JSON path to the array to be used when populating the select options
 * @param valueKey - the name of the JSON key to use as the select value
 * @param textKey - the name of the JSON key to use as the select text
 * @param ommisions
 * @param promotions
 * @param addDivider
 * @param dividerText
 * @param sort
 * @param sortField
 */
export function setValuesCollectionFromResource(
  model: string,
  url: string,
  formSchema: any,
  optionsObjectPath: string = "GlobalOptionSet.Options",
  valueKey: string = "Value",
  textKey: string = "Label.UserLocalizedLabel.Label",
  ommisions: string[] = ["N/A"],
  promotions: Array<string> = [],
  addDivider: boolean = false,
  dividerText: string = "---",
  sort: boolean = false,
  sortField: string = "name"
): void {
  // Make a request for a user with a given ID
  axios
    .get(url)
    .then(function (response) {
      // store response data
      let array: any = getJsonValue(response.data, optionsObjectPath);

      if (!array) {
        console.warn(`No collection data object from url '${url}'`);
        return;
      }

      // created structured array
      let structuredArray: IOption[] = [];
      array.forEach((value: any, index: number, array: any) => {
        let option: IOption = {
          id: getJsonValue(value, valueKey),
          value: getJsonValue(value, valueKey),
          name: getJsonValue(value, textKey),
        };

        structuredArray.push(option);
      });

      // omit text keys
      let filteredArray = omitArrayTextKeys(structuredArray, ommisions);

      // sort array
      let sortedArray = filteredArray;
      if (sort && sort === true) {
        sortedArray = sortArrayByProperty(filteredArray, sortField);
      }

      // text keys promotion
      let promotedArray = promoteArrayValues(
        sortedArray,
        promotions,
        addDivider
      );

      // assign array to model
      setFormSchemaModelArray(model, formSchema, promotedArray);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .then(function () {
      // always executed
    });
}

export const setValuesCollectionFromResourceAsync = async (
  model: string,
  url: string,
  formSchema: any,
  optionsObjectPath: string = "GlobalOptionSet.Options",
  valueKey: string = "Value",
  textKey: string = "Label.UserLocalizedLabel.Label",
  ommisions: string[] = ["N/A"],
  promotions: Array<string> = [],
  addDivider: boolean = false,
  dividerText: string = "---",
  sort: boolean = false,
  sortField: string = "name"
): Promise<boolean> => {
  let apiResponse;

  apiResponse = await axios.get(url);

  let array: any = getJsonValue(apiResponse.data, optionsObjectPath);

  if (!array) {
    console.warn(`No collection data object from url '${url}'`);
    return false;
  }

  // created structured array
  let structuredArray: IOption[] = [];
  array.forEach((value: any, index: number, array: any) => {
    let option: IOption = {
      id: getJsonValue(value, valueKey),
      value: getJsonValue(value, valueKey),
      name: getJsonValue(value, textKey),
    };

    structuredArray.push(option);
  });

  // omit text keys
  let filteredArray = omitArrayTextKeys(structuredArray, ommisions);

  // sort array
  let sortedArray = filteredArray;
  if (sort && sort === true) {
    sortedArray = sortArrayByProperty(filteredArray, sortField);
  }

  // text keys promotion
  let promotedArray = promoteArrayValues(sortedArray, promotions, addDivider);

  // assign array to model
  setFormSchemaModelArray(model, formSchema, promotedArray);

  return true;
};

/**
 * Populates a 'select' form control from a json endpoint
 * @param options - the options to apply to the function
 */
export function setValuesCollectionFromResourceWithOptions(
  options: IResourceCollectionOptions
): void {
  // error checking
  if (!options.model) {
    console.log(`option 'model' not specied`);
    return;
  }
  if (!options.url) {
    console.log(`option 'url' not specied`);
    return;
  }
  if (!options.formSchema) {
    console.log(`option 'formSchema' not specied`);
    return;
  }
  if (!options.addDivider) {
    options.addDivider = false;
  }
  if (!options.dividerText) {
    options.dividerText = "---";
  }
  if (options.sort === null || options.sort === undefined) {
    options.sort = false;
  }
  // Make a request for a user with a given ID
  axios
    .get(options.url)
    .then(function (response) {
      // store response data
      let array: any = getJsonValue(
        response.data,
        options.optionsObjectPath || "data"
      );

      if (!array) {
        console.warn(`No collection data object from url '${options.url}'`);
        return;
      }

      // created structured array
      let structuredArray: IOption[] = [];
      array.forEach((value: any, index: number, array: any) => {
        let option: IOption = {
          id: getJsonValue(value, options.valueKey || "id"),
          value: getJsonValue(value, options.valueKey || "id"),
          name: getJsonValue(value, options.textKey || "name"),
        };

        structuredArray.push(option);
      });

      // omit text keys
      let filteredArray = omitArrayTextKeys(
        structuredArray,
        options.ommisions || []
      );

      // sort array
      let sortedArray = filteredArray;
      if (options.sort && options.sort === true) {
        // assign a default sortField if not specified
        if (options.sortField === null || options.sortField === undefined) {
          options.sortField = "name";
        }
        // perform sort on array and return sorted array
        sortedArray = sortArrayByProperty(filteredArray, options.sortField);
      }

      // text keys promotion
      let promotedArray = promoteArrayValues(
        sortedArray,
        options.promotions || [],
        options.addDivider
      );

      // assign array to model
      setFormSchemaModelArray(options.model, options.formSchema, promotedArray);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .then(function () {
      // always executed
    });
}

/**
 * Populates a 'select' form control from a data object
 * @param model - the name of the model to populate inside the formSchema
 * @param data - the data object
 * @param formSchema - the vue-form-generator schema object where the model reside
 * @param optionsObjectPath - the JSON path to the array to be used when populating the select options
 * @param valueKey - the name of the JSON key to use as the select value
 * @param textKey - the name of the JSON key to use as the select text
 * @param ommisions
 * @param promotions
 * @param addDivider
 * @param dividerText
 * @param sort
 * @param sortField
 */
export function setValuesCollectionFromDataObject(
  model: string,
  data: any,
  formSchema: any,
  optionsObjectPath: string = "GlobalOptionSet.Options",
  valueKey: string = "Value",
  textKey: string = "Label.UserLocalizedLabel.Label",
  ommisions: string[] = ["N/A"],
  promotions: Array<string> = [],
  addDivider: boolean = false,
  dividerText: string = "---",
  sort: boolean = false,
  sortField: string = "name"
): void {
  // store response data
  let array: any = getJsonValue(data, optionsObjectPath);

  if (!array) {
    console.warn(`No collection data object found for '${model}'`);
    return;
  }

  // created structured array
  let structuredArray: IOption[] = [];
  array.forEach((value: any, index: number, array: any) => {
    let option: IOption = {
      id: getJsonValue(value, valueKey),
      value: getJsonValue(value, valueKey),
      name: getJsonValue(value, textKey),
    };

    structuredArray.push(option);
  });

  // omit text keys
  let filteredArray = omitArrayTextKeys(structuredArray, ommisions);

  // sort array
  let sortedArray = filteredArray;
  if (sort && sort === true) {
    sortedArray = sortArrayByProperty(filteredArray, sortField);
  }

  // text keys promotion
  let promotedArray = promoteArrayValues(sortedArray, promotions, addDivider);

  // assign array to model
  setFormSchemaModelArray(model, formSchema, promotedArray);
}

/**
 * Populates a 'select' form control from a json endpoint
 * @param options - the options to apply to the function
 */
export function setValuesCollectionFromDataObjectWithOptions(
  options: IDataCollectionOptions
): void {
  // error checking
  if (!options.model) {
    console.log(`option 'model' not specied`);
    return;
  }
  if (!options.formSchema) {
    console.log(`option 'formSchema' not specied`);
    return;
  }
  if (!options.addDivider) {
    options.addDivider = false;
  }
  if (!options.dividerText) {
    options.dividerText = "---";
  }
  if (options.sort === null || options.sort === undefined) {
    options.sort = false;
  }

  let array = [];

  if (!options.optionsObjectPath) {
    array = options.data;
  } else {
    array = getJsonValue(options.data, options.optionsObjectPath || "data");
  }

  if (!array) {
    console.warn(`No collection data object found for '${options.model}'`);
    return;
  }

  if (Array.isArray(array)) {
    console.log("is array yes!");
  }

  // created structured array
  let structuredArray: IOption[] = [];
  array.forEach((value: any, index: number, array: any) => {
    let option: IOption = {
      id: getJsonValue(value, options.valueKey || "id"),
      value: getJsonValue(value, options.valueKey || "id"),
      name: getJsonValue(value, options.textKey || "name"),
    };

    structuredArray.push(option);
  });

  // omit text keys
  let filteredArray = omitArrayTextKeys(
    structuredArray,
    options.ommisions || []
  );

  // sort array
  let sortedArray = filteredArray;
  if (options.sort && options.sort === true) {
    // assign a default sortField if not specified
    if (options.sortField === null || options.sortField === undefined) {
      options.sortField = "name";
    }
    // perform sort on array and return sorted array
    sortedArray = sortArrayByProperty(filteredArray, options.sortField);
  }

  // text keys promotion
  let promotedArray = promoteArrayValues(
    sortedArray,
    options.promotions || [],
    options.addDivider
  );

  // assign array to model
  setFormSchemaModelArray(options.model, options.formSchema, promotedArray);
}

export function nullOrUndefined(val: any) {
  if (typeof val === "undefined" || val === null) {
    return true;
  }
  return false;
}

function zeroPad(num: number, places: number) {
  if (!num) return;
  var zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

export function formattedDate(date: any) {
  if (
    nullOrUndefined(date) ||
    nullOrUndefined(date.year) ||
    nullOrUndefined(date.month) ||
    nullOrUndefined(date.day)
  )
    return;
  var year = date.year;
  var month = zeroPad(date.month, 2);
  var day = zeroPad(date.day, 2);
  return `${year}${month}${day}`;
}

export function required(enabled?: boolean) {
  if (!enabled) return false;
  return enabled;
}

export function getQueryString(name: string, url?: string): string | null {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

/**
 * Converts a javascript iso date string to a date object used as a value by the dayMonthYearPicker form control
 * @param value - the date string in iso format
 */
export function isoDateStringToFormDate(value: string): IDayMonthYear {
  try {
    let date = parseISO(value);
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    } as IDayMonthYear;
  } catch (e) {
    return {
      year: null,
      month: null,
      day: null,
      value: null,
    } as IDayMonthYear;
  }
}

export const checkNavigationGuards: any = <T>(
  state: T,
  nav: INav,
  slides: ISlide[],
  show: boolean
) => {
  console.log("guarding...", show);
  // check for undefined before continuing
  if (!nav.currentSlideIndex) return;
  // get current slide number
  let nextSlideNumber = 0;
  // should this slide be shown?
  if (!boolify(show)) {
    // slide should not be shown.
    // now check whether to navigate forward or backward based on which direction this slide was accessed
    if (nav.navDirection === INavDirection.Forward) {
      // user was navigating forward, so add 1 to slideNumber
      nextSlideNumber = nav.currentSlideIndex + 1 + 1;
    } else {
      // user was navigating backward, so minus 1 to slideNumber
      nextSlideNumber = nav.currentSlideIndex + 1 - 1;
    }
    // get the slide object in the slides array
    var nextSlide = slides[nextSlideNumber - 1];
    // set the nav properties to perform navigation
    nav = {
      currentSlideComponent: nextSlide.name,
      currentSlideIndex: nextSlideNumber - 1,
      navDirection: INavDirection.Forward,
    };
  }
};

export const checkNavigationGuardsWithState: any = <T>(
  state: any,
  show: boolean,
  meta: any = null
): boolean => {
  let showSlide = boolify(show) ?? false;

  // check for undefined before continuing
  if (!state.nav.currentSlideIndex) return false;
  // init var to hold slide number
  let slideNumber = 0;
  // init var to hold slide direction
  let slideDirection = INavDirection.Forward;
  // should this slide be shown?
  if (!showSlide) {
    // slide should not be shown.
    console.log(`slide index ${state.nav.currentSlideIndex} not shown`);
    // now check whether to navigate forward or backward based on which direction this slide was accessed
    if (state.nav.navDirection === INavDirection.Forward) {
      // user was navigating forward, so add 1 to slideNumber
      slideNumber = state.nav.currentSlideIndex + 1 + 1;
      slideDirection = INavDirection.Forward;
    } else {
      // user was navigating backward, so minus 1 to slideNumber
      slideNumber = state.nav.currentSlideIndex + 1 - 1;
      slideDirection = INavDirection.Backward;
    }
    // get the slide object in the slides array
    var nextSlide = state.slides[slideNumber - 1];
    // set the nav properties to perform navigation
    state.nav = {
      currentSlideComponent: nextSlide.name,
      currentSlideIndex: slideNumber - 1,
      navDirection: slideDirection,
    };
    return showSlide;
  }

  return showSlide;
};

/**
 * Go to a specified slide name
 * @param state the state in which to look for slides
 * @param slideName the name of the slide to navigate to
 * @param direction the direction in which you want to navigate
 * @returns {void}
 */
export function goToSlideName(
  state: any,
  slideName: string,
  direction: INavDirection
) {
  // get slide index
  const slideIndex = state.slides.findIndex((x: any) => x.name === slideName);
  if (slideIndex === -1) {
    console.log(
      `Slide '${slideName}' could not be found. Make sure its registered and included in the app state`
    );
    return;
  }
  // obtain slide object using slide number
  var nextSlide = state.slides[slideIndex];
  // set the nav properties to perform navigation
  state.nav = {
    currentSlideComponent: nextSlide.name,
    currentSlideIndex: slideIndex,
    navDirection: direction,
  };
}

/**
 * Go to a next slide in the slides state
 * @param state the state in which to look for slides
 * @param direction the direction in which you want to navigate
 * @returns {void}
 */
export function goToNextSlide(nav: INav, slides: Array<ISlide>) {
  if (nav.currentSlideIndex === undefined) return;
  let requestedSlideIndex = nav.currentSlideIndex + 1;
  let requestedSlide = slides[requestedSlideIndex];
  let requestedSlideName = requestedSlide.name;

  nav.currentSlideComponent = requestedSlideName;
  nav.currentSlideIndex = requestedSlideIndex;
  nav.navDirection = INavDirection.Forward;
}

/**
 * Go to the previous slide in the slides state
 * @param state the state in which to look for slides
 * @param direction the direction in which you want to navigate
 * @returns {void}
 */
export function goToPreviousSlide(nav: INav, slides: Array<ISlide>) {
  if (nav.currentSlideIndex === undefined) return;
  let requestedSlideIndex = nav.currentSlideIndex - 1;
  let requestedSlide = slides[requestedSlideIndex];
  let requestedSlideName = requestedSlide.name;

  nav.currentSlideComponent = requestedSlideName;
  nav.currentSlideIndex = requestedSlideIndex;
  nav.navDirection = INavDirection.Backward;
}

/**
 * Go to a specified slide number
 * @param state the state in which to look for slides
 * @param slideNumber the number of the slide to navigate to
 * @param direction the direction in which you want to navigate
 * @returns {void}
 */
export function goToSlideNumber(
  state: any,
  slideNumber: number,
  direction: INavDirection
) {
  // obtain slide object using slide number
  var nextSlide = state.slides[slideNumber - 1];
  // set the nav properties to perform navigation
  state.nav = {
    currentSlideComponent: nextSlide.name,
    currentSlideIndex: slideNumber - 1,
    navDirection: direction,
  };
}

/**
 * Go to a specified slide number
 * @param state the state in which to look for slides
 * @param slideNumber the number of the slide to navigate to
 * @param direction the direction in which you want to navigate
 * @returns {void}
 */
export function goToSlideIndex(
  state: any,
  slideIndex: number,
  direction: INavDirection
) {
  // obtain slide object using slide number
  var nextSlide = state.slides[slideIndex];
  // set the nav properties to perform navigation
  state.nav = {
    currentSlideComponent: nextSlide.name,
    currentSlideIndex: slideIndex,
    navDirection: direction,
  };
}

var BoolArray = [true, false, "true", "false", "yes", "no", "1", "0", 1, 0];
export function isBoolean(arg: any) {
  if (BoolArray.indexOf(arg) === -1) {
    return false;
  } else {
    return true;
  }
}
export function boolify(arg: any, falseIfNull: boolean = false) {
  if (BoolArray.indexOf(arg) === -1) {
    return falseIfNull ? false : null;
  } else {
    return arg === true ||
      arg === "true" ||
      arg === "yes" ||
      arg === "1" ||
      arg === 1
      ? true
      : false;
  }
}

export function notNullOrUndefined(value: any) {
  return !isNullOrUndefined(value);
}

export function isNullOrUndefined(val: any) {
  return (
    val === undefined ||
    val === null ||
    val === "" ||
    (Array.isArray(val) && val.length === 0)
  );
}

export const getProp = <T, K extends keyof T>(obj: T, key: K) => {
  return obj[key];
};

interface ITransitions {
  SingleSingle: number;
  SingleClosing: number;
  SingleOpening: number;
  SingleOther: number;

  ClosingSingle: number;
  Closinglosing: number;
  ClosingOpening: number;
  ClosingOther: number;

  OpeningSingle: number;
  OpeningClosing: number;
  OpeningOpening: number;
  OpeningOther: number;

  OtherSingle: number;
  OtherClosing: number;
  OtherOpening: number;
  OtherOther: number;
}

export function formatXml(xml: string) {
  var reg = /(>)(<)(\/*)/g;
  var wsexp = / *(.*) +\n/g;
  var contexp = /(<.+>)(.+\n)/g;
  xml = xml
    .replace(reg, "$1\n$2$3")
    .replace(wsexp, "$1\n")
    .replace(contexp, "$1\n$2");
  var pad = 0;
  var formatted = "";
  var lines = xml.split("\n");
  var indent = 0;
  var lastType = "other";
  // 4 types of tags - single, closing, opening, other (text, doctype, comment) - 4*4 = 16 transitions
  var transitionsA: ITransitions = {
    SingleSingle: 0,
    SingleClosing: -1,
    SingleOpening: 0,
    SingleOther: 0,
    ClosingSingle: 0,
    Closinglosing: -1,
    ClosingOpening: 0,
    ClosingOther: 0,
    OpeningSingle: 0,
    OpeningClosing: -1,
    OpeningOpening: 0,
    OpeningOther: 0,
    OtherSingle: 0,
    OtherClosing: -1,
    OtherOpening: 0,
    OtherOther: 0,
  };

  var transitions: Array<IKeyValue> = [
    { key: "single->single", value: 0 },
    { key: "single->closing", value: -1 },
    { key: "single->opening", value: 0 },
    { key: "single->other", value: 0 },
    { key: "closing->single", value: 0 },
    { key: "closing->closing", value: -1 },
    { key: "closing->opening", value: 0 },
    { key: "closing->other", value: 0 },
    { key: "opening->single", value: 1 },
    { key: "opening->closing", value: 0 },
    { key: "opening->opening", value: 1 },
    { key: "opening->other", value: 1 },
    { key: "other->single", value: 0 },
    { key: "other->closing", value: -1 },
    { key: "other->opening", value: 0 },
    { key: "other->other", value: 0 },
  ];

  /*
  var transitionsB: ICodeDescription = {
    "single->single": 0,
    "single->closing": -1,
    "single->opening": 0,
    "single->other": 0,
    "closing->single": 0,
    "closing->closing": -1,
    "closing->opening": 0,
    "closing->other": 0,
    "opening->single": 1,
    "opening->closing": 0,
    "opening->opening": 1,
    "opening->other": 1,
    "other->single": 0,
    "other->closing": -1,
    "other->opening": 0,
    "other->other": 0,
  };
*/

  for (var i = 0; i < lines.length; i++) {
    var ln = lines[i];
    var single = Boolean(ln.match(/<.+\/>/)); // is this line a single tag? ex. <br />
    var closing = Boolean(ln.match(/<\/.+>/)); // is this a closing tag? ex. </a>
    var opening = Boolean(ln.match(/<[^!].*>/)); // is this even a tag (that's not <!something>)
    var type = single
      ? "single"
      : closing
      ? "closing"
      : opening
      ? "opening"
      : "other";
    var fromTo = lastType + "->" + type;
    lastType = type;
    var padding = "";

    let t = transitions.find((x) => x.key === fromTo);
    if (!t) return;

    indent += t.value as number;
    for (var j = 0; j < indent; j++) {
      padding += "    ";
    }

    formatted += padding + ln + "\n";
  }

  return formatted;
}

export function getPropertyName<T extends object>(
  obj: T,
  selector: (x: Record<keyof T, keyof T>) => keyof T
): keyof T {
  const keyRecord = Object.keys(obj).reduce((res, key) => {
    const typedKey = key as keyof T;
    res[typedKey] = typedKey;
    return res;
  }, {} as Record<keyof T, keyof T>);
  return selector(keyRecord);
}

export function isGuid(stringToTest: string) {
  if (stringToTest[0] === "{") {
    stringToTest = stringToTest.substring(1, stringToTest.length - 1);
  }
  var regexGuid =
    /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
  return regexGuid.test(stringToTest);
}

export function handleAxiosError(error: any) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", error.message);
  }
  console.log(error.config);
}

var STRIP_COMMENTS = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/gm;
var ARGUMENT_NAMES = /([^\s,]+)/g;
export function getParamNames(func: Function) {
  var fnStr = func.toString().replace(STRIP_COMMENTS, "");
  var result = fnStr
    .slice(fnStr.indexOf("(") + 1, fnStr.indexOf(")"))
    .match(ARGUMENT_NAMES);
  if (result === null) result = [];
  return result;
}

export function isJson(text: any) {
  if (typeof text !== "string") {
    return false;
  }
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
}

export function tryJsonParse(text: any, defaultValue: string) {
  if (isJson(text)) {
    return JSON.parse(text);
  }
  return defaultValue ? JSON.parse(defaultValue) : null;
}

export function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
  return o[propertyName]; // o[propertyName] is of type T[K]
}

export function setProperty<T, K extends keyof T>(
  o: T,
  propertyName: K,
  newValue: any
): T[K] {
  return (o[propertyName] = newValue); // o[propertyName] is of type T[K]
}

export const setFormSchemaLabel = (
  formSchema: IFormSchema,
  model: string,
  value: string
) => {
  formSchema.groups.forEach((group) => {
    group.fields.forEach((field) => {
      if (field.model === model) {
        field.label = value;
        return;
      }
    });
  });
};

export const indexOfNthStringOccurance = (
  haystack: string,
  needle: string,
  nthOccurance: number = 1
) => {
  var L = haystack.length,
    i = -1;
  while (nthOccurance-- && i++ < L) {
    i = haystack.indexOf(needle, i);
    if (i < 0) break;
  }
  return i;
};

export const getHostnameFromUrl = (url: string) => {
  // run against regex
  const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
  // extract hostname (will be null if no match is found)
  return matches && matches[1];
};
