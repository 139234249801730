import { tr } from "date-fns/locale";
import { IDayMonthYear, IFormField } from "../../common/typings";

export function requiredValidator(
  value: string,
  field: IFormField,
  model: any
) {
  let message = field.meta
    ? field.meta.validationMessage
    : "We need this information to assist you";

  if (typeof value === "undefined" || value === null || value === "") {
    if (field.required) {
      return [message];
    } else {
      return [];
    }
  }
  return null;
}

export function customEmailValidator(
  value: string,
  field: IFormField,
  model: any
) {
  // if required is false and no value is set or the length of field is zero
  if (!field.required && (!value || (value && value.trim().length === 0))) {
    return [];
  }

  if (!value) {
    return ["We need this information to assist you"];
  }

  if (value.trim().length === 0) {
    return ["We need this information to assist you"];
  }

  var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  var passed = re.test(value);

  if (passed) {
    return [];
  } else {
    return ["Specify complete email format. eg. name@website.com "];
  }
}

export function customNumberOnlyValidator(
  value: string,
  field: IFormField,
  model: any | null
) {
  // if required is false and no value is set or the length of field is zero
  if (!field.required && (!value || (value && value.trim().length === 0))) {
    return [];
  }

  if (!value) {
    return ["We need this information to assist you"];
  }

  if (value.trim().length === 0) {
    return ["We need this information to assist you"];
  }

  var re = /^\d+$/;
  var passed = re.test(value);

  if (passed) {
    return [];
  } else {
    return ["Please use numbers only "];
  }
}

export function dayMonthYearPickerValidator(
  value: IDayMonthYear,
  field: IFormField
) {
  if (field.required === false) return [];
  var fields = [];
  if (!value.year) fields.push("year");
  if (!value.month) fields.push("month");
  if (!value.day) fields.push("day");

  if (fields.length > 0) {
    return ["Please select: " + fields.join(", ")];
  } else {
    return [];
  }
}

export function nationalInsuranceNumberValidator(
  value: string,
  field: IFormField,
  model: any | null
): string[] {
  // if required is false and no value is set or the length of field is zero
  if (!field.required && (!value || (value && value.trim().length === 0))) {
    return [];
  }

  if (!value) {
    return ["We need this information to assist you"];
  }

  if (value.trim().length === 0) {
    return ["We need this information to assist you"];
  }

  let pattern = /^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]|\s)$/gi;
  if (pattern.test(value) === false) {
    return ["Invalid NI number entered. It should be in the format AA123456A"];
  }

  return [];
}

export function southAfricanIdNumberValidator(
  value: string,
  field: IFormField,
  model: any | null
): string[] {
  // if required is false and no value is set or the length of field is zero
  if (!field.required && (!value || (value && value.trim().length === 0))) {
    return [];
  }

  if (!value) {
    return ["We need this information to assist you"];
  }

  if (value.trim().length === 0) {
    return ["We need this information to assist you"];
  }

  // test length
  if (value.length < 13) {
    return ["Number should be 13 digits long"];
  }

  // south african id regex validation
  let pattern = /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))$/gi;
  if (pattern.test(value) === false) {
    return ["Invalid South African ID number entered. Please check the value"];
  }

  return [];
}

export function southAfricanTaxNumberValidator(
  value: string,
  field: IFormField,
  model: any | null
): string[] {
  // if required is false and no value is set or the length of field is zero
  if (!field.required && (!value || (value && value.trim().length === 0))) {
    return [];
  }

  if (!value) {
    return ["We need this information to assist you"];
  }

  if (value.trim().length === 0) {
    return ["We need this information to assist you"];
  }

  // test length
  if (value.length < 10) {
    return ["Number should be 10 digits long"];
  }

  // test numeric only
  let pattern = /^\d+$/;
  if (pattern.test(value) === false) {
    return ["No letters allowed, please use numbers only"];
  }

  return [];
}
