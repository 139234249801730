import Vue from "vue";
import { ILeadFormState } from "../typings/local";

const store = Vue.observable({
  state: {} as ILeadFormState,
});

// properties
const state = Vue.observable<ILeadFormState>({
  version: "20201003.1543",
  formModel: {
    misc: {
      saTaxNo: true,
      saTaxNoEnabled: null,
    },
    lead: {
      firstname: null,
      lastname: null,
      emailaddress1: null,
      mobilephone: null,
      description: null,
      new_primarymailinglist: null,
      new_fxmrkreportmailinglist: null,
      new_businessunit: null,
      new_accountingbusinessdivisions: null,
      leadsourcecode: null,
      subject: null,
      new_leadsourceurl: null,
      new_country: null,
      new_consentrelevantservices: null,
      new_investmentcurrency: null,
      new_investmentfunds: null,
      new_sarstaxnumber: null,
      newtaxrequirement: null,
      newtaxrequirementrequired:null,
      newtaxrequirementenabled:null,
      newtaxrequirementordinal:null,
      newtaxrequirementlabel:null,
      //new_dateofbirth:null,
      //new_dateofbirthrequired:null,
      //new_dateofbirthenabled:null,
      //new_dateofbirthordinal:null,
      //new_dateofbirthlabel:null,
    },
  },
  formUrls: {
    apiUrl: "",
  },
  nav: {},
  slides: [
    {
      name: "Slide1",
    },
    {
      name: "Submission",
    },
    {
      name: "Success",
    },
  ],
  appSettings: [
    {
      key: "data-api-url",
      required: true,
    },
    {
      key: "data-business-unit",
      required: true,
      requiredValidationMessage: "You must set the business unit",
    },
    {
      key: "data-sub-business-unit",
      required: true,
      requiredValidationMessage: "You must set the sub business unit",
    },
    {
      key: "data-lead-source",
      required: true,
      requiredValidationMessage: "You must set the lead source",
    },
    {
      key: "data-call-to-action",
      required: true,
      requiredValidationMessage: "You must set the call to action text",
    },
    {
      key: "data-subscriptions",
      required: false,
    },
    {
      key: "data-amount-field-enabled",
      required: false,
    },
    {
      key: "data-amount-field-required",
      required: false,
    },
    {
      key: "data-amount-field-label",
      required: false,
    },
    {
      key: "data-amount-field-ordinal",
      required: false,
    },    
    {
      key: "data-amount-field-defaultcurrency",
      required: false,
    },
    {
      key: "data-new-tax-requirement-required",
      required: false,
    },
    {
      key: "data-new-tax-requirement-ordinal",
      required: false,
    },
    {
      key: "data-new-tax-requirement-enabled",
      required: true,
      requiredValidationMessage: "You must set the new tax requirement enabled value",
    },
    {
      key: "data-new-tax-requirement-label",
      required: false,
    },
    {
      key: "data-new-sarstax-enabled",
      required: false,
    },
  ],
});

export function useState() {
  return {
    state,
  };
}
