import { ICurrency } from "../typings";

export default [
  {
    currencyIsoCode: "aed",
    currencySymbol: "د.إ",
    currencyName: "Emirati dirham",
    countryThreeLetterIsoCode: "are",
    countryTwoLetterIsoCode: "ae",
  },
  {
    currencyIsoCode: "aud",
    currencySymbol: "$",
    currencyName: "Australia Dollar",
    countryThreeLetterIsoCode: "aus",
    countryTwoLetterIsoCode: "au",
  },
  {
    currencyIsoCode: "bgn",
    currencySymbol: "лв",
    currencyName: "Bulgarian Lev",
    countryThreeLetterIsoCode: "bgr",
    countryTwoLetterIsoCode: "bg",
  },
  {
    currencyIsoCode: "bwp",
    currencySymbol: "P",
    currencyName: "Botswana Pula",
    countryThreeLetterIsoCode: "bwa",
    countryTwoLetterIsoCode: "bw",
  },
  {
    currencyIsoCode: "cad",
    currencySymbol: "$",
    currencyName: "Canadian Dollar",
    countryThreeLetterIsoCode: "can",
    countryTwoLetterIsoCode: "ca",
  },
  {
    currencyIsoCode: "czk",
    currencySymbol: "Kč",
    currencyName: "Czech Koruna",
    countryThreeLetterIsoCode: "cze",
    countryTwoLetterIsoCode: "cz",
  },
  {
    currencyIsoCode: "dkk",
    currencySymbol: "kr",
    currencyName: "Danish Krone",
    countryThreeLetterIsoCode: "dnk",
    countryTwoLetterIsoCode: "dk",
  },
  {
    currencyIsoCode: "eur",
    currencySymbol: "€",
    currencyName: "Euro",
    countryThreeLetterIsoCode: "eur",
    countryTwoLetterIsoCode: "eu",
  },
  {
    currencyIsoCode: "gbp",
    currencySymbol: "£",
    currencyName: "Pound Sterling",
    countryThreeLetterIsoCode: "gbr",
    countryTwoLetterIsoCode: "gb",
  },
  {
    currencyIsoCode: "hkd",
    currencySymbol: "HK$",
    currencyName: "Hong Kong Dollar",
    countryThreeLetterIsoCode: "hkg",
    countryTwoLetterIsoCode: "hk",
  },
  {
    currencyIsoCode: "huf",
    currencySymbol: "ft",
    currencyName: "Hungarian Forint",
    countryThreeLetterIsoCode: "hun",
    countryTwoLetterIsoCode: "hu",
  },
  {
    currencyIsoCode: "ils",
    currencySymbol: "₪",
    currencyName: "Israeli Shekel",
    countryThreeLetterIsoCode: "isr",
    countryTwoLetterIsoCode: "il",
  },
  {
    currencyIsoCode: "inr",
    currencySymbol: "₹",
    currencyName: "Indian rupee",
    countryThreeLetterIsoCode: "ind",
    countryTwoLetterIsoCode: "in",
  },
  {
    currencyIsoCode: "jpy",
    currencySymbol: "¥",
    currencyName: "Japanese Yen",
    countryThreeLetterIsoCode: "jpn",
    countryTwoLetterIsoCode: "jp",
  },
  {
    currencyIsoCode: "ngn",
    currencySymbol: "₦",
    currencyName: "Nigerian naira",
    countryThreeLetterIsoCode: "ngn",
    countryTwoLetterIsoCode: "ng",
  },
  {
    currencyIsoCode: "kes",
    currencySymbol: "Sh",
    currencyName: "Kenyan Shilling",
    countryThreeLetterIsoCode: "ken",
    countryTwoLetterIsoCode: "ke",
  },
  {
    currencyIsoCode: "mur",
    currencySymbol: "Rs",
    currencyName: "Mauritius Rupee",
    countryThreeLetterIsoCode: "mus",
    countryTwoLetterIsoCode: "mu",
  },
  {
    currencyIsoCode: "nzd",
    currencySymbol: "$",
    currencyName: "New Zealand Dollar",
    countryThreeLetterIsoCode: "nzl",
    countryTwoLetterIsoCode: "nz",
  },
  {
    currencyIsoCode: "nok",
    currencySymbol: "kr",
    currencyName: "Norwegian Krone",
    countryThreeLetterIsoCode: "nor",
    countryTwoLetterIsoCode: "no",
  },
  {
    currencyIsoCode: "pln",
    currencySymbol: "zł",
    currencyName: "Polish PZloty",
    countryThreeLetterIsoCode: "pol",
    countryTwoLetterIsoCode: "pl",
  },
  {
    currencyIsoCode: "qar",
    currencySymbol: "ر.ق",
    currencyName: "Qatari Rial",
    countryThreeLetterIsoCode: "qat",
    countryTwoLetterIsoCode: "qa",
  },
  {
    currencyIsoCode: "ron",
    currencySymbol: "lei",
    currencyName: "Romanian Leu",
    countryThreeLetterIsoCode: "rou",
    countryTwoLetterIsoCode: "ro",
  },
  {
    currencyIsoCode: "sgd",
    currencySymbol: "$",
    currencyName: "Singapore Dollar",
    countryThreeLetterIsoCode: "sgp",
    countryTwoLetterIsoCode: "sg",
  },
  {
    currencyIsoCode: "sek",
    currencySymbol: "kr",
    currencyName: "Swedish Krona",
    countryThreeLetterIsoCode: "swe",
    countryTwoLetterIsoCode: "se",
  },
  {
    currencyIsoCode: "chf",
    currencySymbol: "CHF",
    currencyName: "Swiss Franc",
    countryThreeLetterIsoCode: "che",
    countryTwoLetterIsoCode: "ch",
  },
  {
    currencyIsoCode: "thb",
    currencySymbol: "฿",
    currencyName: "Thai Baht",
    countryThreeLetterIsoCode: "tha",
    countryTwoLetterIsoCode: "th",
  },
  {
    currencyIsoCode: "try",
    currencySymbol: "₤",
    currencyName: "Turkish Lira",
    countryThreeLetterIsoCode: "tur",
    countryTwoLetterIsoCode: "tr",
  },
  {
    currencyIsoCode: "ugx",
    currencySymbol: "USh",
    currencyName: "	Ugandan shilling",
    countryThreeLetterIsoCode: "uga",
    countryTwoLetterIsoCode: "ug",
  },
  {
    currencyIsoCode: "usd",
    currencySymbol: "$",
    currencyName: "United States Dollar",
    countryThreeLetterIsoCode: "usa",
    countryTwoLetterIsoCode: "us",
  },
  {
    currencyIsoCode: "zar",
    currencySymbol: "R",
    currencyName: "South African Rand",
    countryThreeLetterIsoCode: "zaf",
    countryTwoLetterIsoCode: "za",
  },
  {
    currencyIsoCode: "zmw",
    currencySymbol: "K",
    currencyName: "Zambian Kwacha",
    countryThreeLetterIsoCode: "zmb",
    countryTwoLetterIsoCode: "zm",
  },
] as ICurrency[];
