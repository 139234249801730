<template>
  <div class="form-check">
    <label :class="innerLabelClasses">
      <input
        :id="getFieldID(schema)"
        type="checkbox"
        v-model="value"
        :autocomplete="schema.autocomplete"
        :disabled="disabled"
        :name="schema.inputName"
        :class="[schema.fieldClasses, 'form-check-input']"
        :required="schema.required"
        v-attributes="'input'"
        ref="checkbox"
        :true-value="schema.checkboxInlineBooleanTrueValue"
        :false-value="schema.checkboxInlineBooleanFalseValue"
      />
      {{ innerLabel }}
    </label>
  </div>
</template>

<script>
import VueFormGeneratorCustomTs from "vue-form-generator-custom-ts";

export default {
  name: "fieldCheckboxInline",
  mixins: [VueFormGeneratorCustomTs.abstractField],
  data() {
    return {
      id: "",
      innerLabel: "",
      innerLabelClasses: ""
    };
  },
  created() {},
  beforeMount() {},
  mounted() {
    let element = this.$refs.checkbox;
    this.id = element.getAttribute("id");
    this.innerLabel = element.getAttribute("data-inner-label");
    this.innerLabelClasses =
      element.getAttribute("data-inner-label-classes") ?? "";
    this.trueValue = element.getAttribute("data-true-value") ?? true;
    this.falseValue = element.getAttribute("data-false-value") ?? false;
  },
  updated() {}
};
</script>

<style lang="scss">
.field-checkboxInline > label {
  display: none;
}
</style>
