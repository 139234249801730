type Nullable<T> = T | null;

export interface TypedObject<T> {
  [index: string]: T;
}

export interface IFormUrls {
  apiUrl?: string;
  baseUrl?: string;
  successRedirectUrl?: string;
  absoluteSuccessRedirectUrl?: string;
}

export interface FormSetting {
  key: string;
  value: string | null;
}

export interface ISetting {
  key: string;
  value: string | null;
}

export interface IAppSetting {
  key: string;
  value?: string;
  required?: boolean;
  requiredValidationMessage?: string;
  valueIfNotSet?: string | null;
}

export interface AppSettingValidation {
  key: string;
  value: string | null;
  requiredValidationMessage: string;
}

export interface KeyValue {
  key: string;
  value: string | null;
}

export enum INavDirection {
  Backward = 0,
  Forward = 1,
}

export interface INav {
  currentSlideIndex?: number;
  totalSlides?: number;
  currentSlideComponent?: string;
  navDirection?: INavDirection;
}

export interface ISlide {
  name: string;
  number?: number;
  isActive?: boolean;
}

export interface ISettings {
  apiUri: string;
  baseUrl: string;
  successRedirectUrl: string;
  taxRefundsBusiness: string;
}

export interface IFormSchema {
  groups: IFormGroup[];
}

export interface IFormGroup {
  legend?: string;
  legendClass?: string;
  fields: Array<IFormField>;
}

export interface IRadiosOptions {
  value?: string;
  name?: string;
}

export interface ISelectOptions {
  multiSelect?: boolean;
  closeOnSelect?: boolean;
  searchable?: boolean;
  showLabels?: boolean;
  noneSelectedText?: string;
  hideNoneSelectedText?: boolean;
  value?: string;
  name?: string;
}

export interface IAmountWithCurrencyIdMapping {
  currencyIsoCode: string;
  currencyId: string;
}

export class IAmountWithCurrencyCodeOptions {
  // property to use from currency object as currency code value
  valueProperty: string | null;
  // the currency to be selected on the first load
  defaultCurrency: string | null;
  // the field in the vfg model you want to map the selected currency value to
  currencyCodeVfgModelField?: string | null;
  // the field in the vfg model you want to map the amount value to
  currencyAmountVfgModelField?: string | null;
  // an array of custom options to merge with built in options
  customCurrencies?: [];
  // field to use when merging custom options and built in options
  customCurrenciesMergeField: string;
  geolocation?: {
    enabled: boolean;
    endpoint: string;
    countryCodeProperty: string;
  };

  constructor() {
    this.valueProperty = null;
    this.defaultCurrency = null;
    this.currencyCodeVfgModelField = null;
    this.currencyAmountVfgModelField = null;
    this.customCurrencies = [];
    this.customCurrenciesMergeField = "currencyIsoCode";
    this.geolocation = {
      enabled: true,
      endpoint: "https://webservices.1stcontact.com/apis/geolocation",
      countryCodeProperty: "geoplugin_countryCode",
    };
  }
}

export interface IFormField {
  type?: string;
  label?: string;
  model?: string;
  featured?: boolean;
  visible?: boolean | Function;
  disabled?: boolean | Function;
  required?: boolean | Function;
  default?: any;
  help?: string;
  hint?: string;
  styleClasses?: string | Array<string>;
  inputType?: string;
  rows?: number;
  min?: number;
  max?: number;
  maxlength?: number;
  placeholder?: string;
  validator?: Function;
  values?: Array<IOption> | Function;
  radiosOptions?: IRadiosOptions;
  attributes?: any;
  checkboxInlineBoolean?: ICheckboxInlineBoolean | null;
  checkboxInlineBooleanTrueValue?: any;
  checkboxInlineBooleanFalseValue?: any;
  // dob fields
  years?: Array<any>;
  months?: Array<any>;
  days?: Array<any>;
  meta?: any;
  selectOptions?: ISelectOptions;
  amountWithCurrencyCodeOptions?: IAmountWithCurrencyCodeOptions;
  defaultcurrency?: string;
  options?: any;
}

// IOption
// - radio, checklist - value/name
// - select - id/name
export interface IOption {
  id?: any;
  value?: any;
  name: string;
}

export interface IDayMonthYear {
  day?: number | null;
  month?: number | null;
  year?: number | null;
  value?: string | null;
}

interface IVfgField {
  type: string;
  label: string;
  model: string;
  id: string;
  inputName: string;
  featured: boolean;
  visible: boolean;
  disabled: boolean;
  required: boolean;
  multi: boolean;
  default: any;
  hint: string;
  help: string;
  validator: Function;
  validateDebounceTime: number;
  styleClasses: string | string[];
  buttons: any[];
  attributes: {};
}

export interface IKeyValue {
  key: string;
  value: string | number | boolean;
}

export interface ICodeDescription {
  Code: number;
  Description: string;
}

export interface IdNameValue {
  id: string | number;
  name: string;
  value?: string | number;
}

export interface IUiError {
  show: boolean;
  message: string;
  reveal: boolean;
}

export interface IResourceCollectionOptions {
  model: string;
  url: string;
  formSchema: IFormSchema;
  optionsObjectPath?: string;
  valueKey?: string;
  textKey?: string;
  ommisions?: Array<string>;
  promotions?: Array<string>;
  addDivider?: boolean;
  dividerText?: string;
  sort?: boolean;
  sortField?: string;
}

export interface IDataCollectionOptions {
  model: string;
  data: any;
  formSchema: IFormSchema;
  optionsObjectPath?: string;
  valueKey?: string;
  textKey?: string;
  ommisions?: Array<string>;
  promotions?: Array<string>;
  addDivider?: boolean;
  dividerText?: string;
  sort?: boolean;
  sortField?: string;
}

export interface IAppUrls {
  crmProxyUrl: string;
  sableApiUrl: string;
  forexSecureUrl: string;
  paymentUrl: string;
}

export interface INewOptionWebFilter {
  new_entityname: string;
  new_fieldname: string;
  new_frontendform: string;
  new_name: string;
  new_optionsetname: string;
  new_optionwebfilterid: string;
  new_value: number;
  statecode: IValueType;
}

export interface IValueType {
  Value: number;
}

export interface ICrmPriceLevel {
  _transactioncurrencyid_value?: string;
  name?: string;
  pricelevelid: string;
  transactioncurrencyid: ICrmTransactionCurrency;
}

export interface ICrmTransactionCurrency {
  currencysymbol?: string;
  isocurrencycode?: string;
  transactioncurrencyid: string;
}

export interface ICrmSalesOrderDetail {
  productid: string;
  uomid: string;
  priceperunit: number;
  quantity: number;
  extendedamount: number;
}

export interface ICrmProductPriceLevel {
  _productid_value: string;
  _transactioncurrencyid_value: string;
  productpricelevelid: string;
  _pricelevelid_value: string;
  amount: number;
}

export interface ICrmProduct {
  productid: string;
  name: string;
  statecode: number;
  productnumber: string;
  producttypecode: number;
}

export interface ICrmProductAssociation {
  productassociationid: string;
  _associatedproduct_value: string;
  _uomid_value: string;
  statecode: number;
  statuscode: number;
  associatedProduct?: ICrmProduct;
}

export interface ICrmPackage extends IExtendedProps {
  productId: string;
  productPriceLevel?: ICrmProductPriceLevel;
  product?: ICrmProduct;
  productAssociations?: Array<ICrmProductAssociation>;
}

interface IExtendedProps {
  selected: boolean;
  sort: number;
}

export interface IStringSort {
  value: string;
  sort: number;
}

export interface IPageSchema {}

export interface IPage {
  name: string;
  schema: IFormSchema;
}

export interface PageData {
  pages: Array<IPage>;
}

export interface ICheckboxInlineBoolean {
  true: any;
  false: any;
}

export interface ITransactionCurrency {
  currencyname: string;
  currencysymbol: string;
  exchangerate: number;
  isocurrencycode: string;
  transactioncurrencyid: string;
}

export interface ICountry {
  id?: string;
  currencyIsoCode: string;
  currencyName: string;
  currencySymbol?: string;
  countryThreeLetterIsoCode: string;
  countryTwoLetterIsoCode: string;
}

export interface ICallingCode {
  countryTwoLetterIsoCode: string;
  callingCode: string;
}

export interface ICurrency {
  currencyIsoCode: string;
  currencyName: string;
  currencySymbol?: string;
  countryThreeLetterIsoCode: string;
  countryTwoLetterIsoCode: string;
}

export interface IGeoPluginResponse {
  geoplugin_request: string;
  geoplugin_status: number;
  geoplugin_delay: string;
  geoplugin_credit: string;
  geoplugin_city: string;
  geoplugin_region: string;
  geoplugin_regionCode: string;
  geoplugin_regionName: string;
  geoplugin_areaCode: string;
  geoplugin_dmaCode: string;
  geoplugin_countryCode: string;
  geoplugin_countryName: string;
  geoplugin_inEU: number;
  geoplugin_euVATrate: boolean;
  geoplugin_continentCode: string;
  geoplugin_continentName: string;
  geoplugin_latitude: string;
  geoplugin_longitude: string;
  geoplugin_locationAccuracyRadius: string;
  geoplugin_timezone: string;
  geoplugin_currencyCode: string;
  geoplugin_currencySymbol: string;
  geoplugin_currencySymbol_UTF8: string;
  geoplugin_currencyConverter: number;
}
