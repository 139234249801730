<template>
  <div class="form-check">
    <input
      :id="getFieldID(schema)"
      type="checkbox"
      v-model="value"
      :autocomplete="schema.autocomplete"
      :disabled="disabled"
      :name="schema.inputName"
      :class="[schema.fieldClasses, 'form-check-input']"
      :required="schema.required"
      v-attributes="'input'"
      ref="checkbox"
      :true-value="trueValue"
      :false-value="falseValue"
    />
    <label class="form-check-label" :for="id">{{ innerLabel }}</label>
  </div>
</template>

<script>
import VueFormGeneratorCustomTs from "vue-form-generator-custom-ts";

export default {
  name: "fieldCheckboxCustom",
  mixins: [VueFormGeneratorCustomTs.abstractField],
  data() {
    return {
      id: "",
      innerLabel: "",
      trueValue: true,
      falseValue: false,
    };
  },
  mounted() {
    let element = this.$refs.checkbox;
    this.id = element.getAttribute("id");
    this.innerLabel = element.getAttribute("data-inner-label");
    this.trueValue = element.getAttribute("data-true-value") ?? true;
    this.falseValue = element.getAttribute("data-false-value") ?? false;
  },
};
</script>

<style lang="scss">
.vue-form-generator .field-checkbox input {
  margin-left: 12px;
}
</style>
