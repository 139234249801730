<template>
  <div class="form-check" />
</template>

<script>
import VueFormGeneratorCustomTs from "vue-form-generator-custom-ts";

export default {
  name: "fieldLabelCustom",
  mixins: [VueFormGeneratorCustomTs.abstractField],
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="scss">
</style>
