// ie 10 compatibility imports
import "core-js/features/object/assign";
import "core-js/features/array/find";
import "core-js/features/array/find-index";
import "core-js/features/promise";
import "regenerator-runtime/runtime";

import Vue from "vue";
Vue.config.productionTip = false;

import VueCompositionApi from "@vue/composition-api";
Vue.use(VueCompositionApi);

const appRootElementId = "LeadFormWidget";

import {
  getAppSettings,
  parseAppSettings,
  getFormUrls,
} from "../../common/composables/useFormHelper";

import { useState } from "./store";
const { state } = useState();

// **********************************************
const root = document.getElementById(appRootElementId);
const formUrls = getFormUrls(root);
const settings = getAppSettings(root);
const appSettings = getAppSettings(root);
//const appSettings = parseAppSettings(state.appSettings, settings);

//state.formUrls = formUrls;
//state.appSettings = appSettings;
// **********************************************

import VueFormGeneratorCustomTs from "vue-form-generator-custom-ts";
Vue.use(VueFormGeneratorCustomTs);

import fieldCheckboxCustom from "../../common/components/custom-vfg-fields/fieldCheckboxCustom.vue";
Vue.component("fieldCheckboxCustom", fieldCheckboxCustom);

import fieldLabelCustom from "../../common/components/custom-vfg-fields/fieldLabelCustom.vue";
Vue.component("fieldLabelCustom", fieldLabelCustom);

import fieldCheckboxInline from "../../common/components/custom-vfg-fields/fieldCheckboxInline.vue";
Vue.component("fieldCheckboxInline", fieldCheckboxInline);

import fieldCheckboxInlineSimple from "../../common/components/custom-vfg-fields/fieldCheckboxInlineSimple.vue";
Vue.component("fieldCheckboxInlineSimple", fieldCheckboxInlineSimple);

//import fieldAmountWithCurrencyCode from "../../common/components/custom-vfg-fields/fieldAmountWithCurrencyCode.vue";
//Vue.component("fieldAmountWithCurrencyCode", fieldAmountWithCurrencyCode);

import fieldAmountWithCurrencyCodeNg from "../../common/components/custom-vfg-fields/fieldAmountWithCurrencyCodeNg.vue";
Vue.component("fieldAmountWithCurrencyCodeNg", fieldAmountWithCurrencyCodeNg);

import fieldPhoneNumberWithCountryCode from "../../common/components/custom-vfg-fields/fieldPhoneNumberWithCountryCode.vue";
Vue.component(
  "fieldPhoneNumberWithCountryCode",
  fieldPhoneNumberWithCountryCode
);


/**
 * style imports
 */
import "../../../node_modules/vue-form-generator-custom-ts/dist/index.css";
import "../../common/scss/style.scss";

import Form from "./components/Form.vue";

new Vue({
  render: (h) =>
    h(Form, {
      props: {
        formUrls: formUrls,
        appSettings: appSettings,
      },
    }),
}).$mount(`#${appRootElementId}`);
